@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-bottom: var(--padding-section);

  @include devices(tablet) {
    gap: 45px;
    margin-bottom: 160px;
  }

  @include devices(mobile) {
    gap: 30px;
    margin-bottom: 80px;
  }
}