.container {
  width: 100%;
}

.loading {
  position: relative;
  padding-bottom: 100%;
}

.skeleton {
  position: absolute;
  inset: 0;
}