@import "@/shared/scss/mixins/devices";

.container {

  @include devices(tablet) {
    margin-bottom:160px;
  }

  @include devices(mobile) {
    margin-bottom: 60px;
  }
}