@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.header__xl {
  font-size: clamp(36px, get-vw(48px, 1920px), 48px);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(30px, get-vw(58px, 1920px), 58px);

  @include devices(tablet) {
    font-size: min(36px, get-vw(36px, 1200px));
  }

  @include devices(mobile) {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }
}

.header__l {
  font-size: clamp(38px, get-vw(48px, 1920px), 48px);
  font-style: normal;
  font-weight: 300;
  line-height: clamp(48px, get-vw(58px, 1920px), 58px);

  @include devices(tablet) {
    font-size: min(38px, get-vw(38px, 1200px));
    line-height: min(48px, get-vw(48px, 1200px));
  }

  @include devices(mobile) {
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
  }
}

.header__m {
  font-size: min(35px, get-vw(356px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(48px, get-vw(48px, 1920px));

  @include devices(tablet) {
    font-size: min(32px, get-vw(32px, 1200px));
    line-height: min(38px, get-vw(38px, 1200px));
  }

  @include devices(mobile) {
    font-size: 20px;
    line-height: 26px; /* 130% */
    letter-spacing: 0.2px;
  }
}

.header__s {
  font-size: min(26px, get-vw(26px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(48px, get-vw(48px, 1920px));

  @include devices(tablet) {
    line-height: min(38px, get-vw(38px, 1200px));
  }

  @include devices(mobile) {
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
  }
}

.paragraph {
  font-size: min(19px, get-vw(19px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(35px, get-vw(35px, 1920px)); /* 184.211% */
  letter-spacing: min(0.19px, get-vw(0.19px, 1920px));

  @include devices(tablet) {
    font-size: min(15px, get-vw(15px, 1200px));
    line-height: min(26px, get-vw(26px, 1200px));
    letter-spacing: 0.01em;
  }

  @include devices(mobile) {
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.01em;
  }
}

.caps-lock {
  font-size: min(15px, get-vw(15px, 1920px));
  font-style: normal;
  font-weight: 500;
  line-height: min(35px, get-vw(35px, 1920px)); /* 233.333% */
  letter-spacing: 0.15px;
  text-transform: uppercase;

  @include devices(tablet) {
    font-size: min(15px, get-vw(15px, 1200px));
    line-height: min(35px, get-vw(35px, 1200px));
  }

  @include devices(mobile) {
    font-size: 13px;
    line-height: 20px; /* 153.846% */
    letter-spacing: 0.13px;
  }
}

.small {
  font-size: min(16px, get-vw(16px, 1920px));
  font-weight: 300;
  line-height: min(24px, get-vw(24px, 1920px));
  letter-spacing: 0.01em;

  @include devices(tablet) {
    font-size: min(16px, get-vw(16px, 1200px));
    line-height: min(24px, get-vw(24px, 1200px));
  }

  @include devices(tablet) {
    font-size: 16px;
    line-height: 24px;
  }
}