@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  position: relative;
}

.articles {
  display: none;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  overflow: auto;
  margin: 0 auto;
  width: 100%;
  padding: 0 min(150px, get-vw(150px, 1920px));

  @include devices(tablet) {
    padding: 0 min(42px, get-vw(42px, 1200px));
  }

  @include devices(mobile) {
    padding: 0 12px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.active {
  display: grid;

  @include devices(mobile) {
    display: flex;

    > * {
      width: 100%;
      flex-shrink: 0;
    }
  }
}