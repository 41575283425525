@import "@/shared/scss/mixins/devices";

.section {

  @include devices(mobile) {
    margin-bottom: 80px;
    padding: 0!important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 51px;
  align-items: center;
  flex: 1;
  justify-content: center;
  text-align: center;

  @include devices(tablet) {
    justify-content: flex-start;
  }
}

.button {
  max-width: 221px;
  margin: 0 auto;
}

.header {
  margin-bottom: var(--padding-section);

  @include devices(mobile) {
    margin-bottom: 30px;
  }
}

.text {
  margin-bottom: 40px;

  @include devices(mobile) {
    margin-bottom: 20px;
  }
}