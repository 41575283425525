@import "@/shared/scss/mixins/get-vw";
@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  gap: 36px;
  padding-right: min(150px, get-vw(150px, 1920px));

  @include devices(tablet) {
    gap: 10px;
    padding-right: 0;
  }

  @include devices(mobile) {
    flex-direction: column;
    gap: 0
  }
}

.map {
  width: 100%;
  height: 671px;
  overflow: hidden;

  svg {
    height: 100%;
  }

  @include devices(tablet) {
    width: 100%;
  }

  @include devices(mobile) {
    height: 346px;
    width: 100%;
    order: 1;
  }
}