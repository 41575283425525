@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  color: black;
  display: grid;
  grid-template-columns: 1fr 42px;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
  padding: min(41px, get-vw(41px, 1920px)) min(56px, get-vw(56px, 1920px));
  border: 1px solid #E3B486;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: #FFF5EB;
  }

  @include devices(tablet) {
    padding: min(31px, get-vw(31px, 1200px));
  }

  @include devices(mobile) {
    gap: 20px;
    grid-template-columns: 1fr 36px;
    padding: 20px;
  }
}

.icon {
  width: min(42px, get-vw(42px, 1920px));
  height: min(42px, get-vw(42px, 1920px));
  display: flex;
  align-items: center;
  justify-content: center;

  @include devices(tablet) {
    width: min(42px, get-vw(42px, 1200px));
    height: min(42px, get-vw(42px, 1200px));
  }

  @include devices(mobile) {
    width: 36px;
    height: 36px;
  }
}

.title {
  color: black;
  font-size: min(26px, get-vw(26px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(40px, get-vw(40px, 1920px)); /* 153.846% */


  &:hover {
    color: black;
    text-decoration-line: underline;
  }

  @include devices(mobile) {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
  }
}

.poster {
  color: black;
  position: relative;
  padding-bottom: 50%;

  img {
    object-fit: cover;
  }

  @include devices(mobile) {
    padding-bottom: 75%;
  }
}