@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  flex: 1;
  min-height: 115px;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: var(--light);
  }

  @include devices(tablet) {
    padding: 18px;
  }

  @media (width <= 900px){
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 204px;
    min-width: 204px;
    padding: 16px 27px;
  }
}