@import "@/shared/scss/mixins/devices";

.container {
  padding: 0 150px;
  margin-bottom: 170px;

  @include devices(tablet) {
    margin-bottom: 145px;
    padding: 0 42px;
  }

  @include devices(mobile) {
    margin-bottom: 82px;
    padding: 0;
  }
}