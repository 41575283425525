@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  flex-flow: column;
  gap: 50px;
  max-width: 852px;
  margin: 0 auto;
  text-align: center;
  align-items: center;

  @include devices(mobile) {
    padding: 0 12px;
    gap: 30px;
  }
}

.line {
  position: relative;
  max-width: 448px;
  height: 2px;
  width: 100%;
  background-color: var(--light-gold);
  transition: all .8s ease-in-out;
  transform: scaleX(0);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgb(248 179 112 / 0%), #fcc0b0 40%, #ffa258 50%, #ffe9c8 70%, rgb(248 179 112 / 0%));
    transform: translate(-100%, 0);
    z-index: 1;
  }
}

.container-in-view {
  .line {
    transform: scaleX(1);

    &::before {
      animation: animated-blink 3s infinite;
    }
  }
}

@keyframes animated-blink {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}