@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  position: relative;
  margin-bottom: 116px;

  @include devices(tablet) {
    margin-bottom: 76px;
  }

  @include devices(mobile) {
    margin-bottom: 100px;
  }
}

.background {
  position: relative;
  display: flex;
  min-height: 78.1481vh;

  img,
  video {
    object-fit: cover;
  }

  @include devices(mobile) {
    min-height: 450px;
  }
}

.box {
  position: relative;
  max-width: 944px;
  background-color: var(--white);
  padding: 33px 56px 0 min(150px, get-vw(150px, 1920px));
  margin-top: clamp(-100px, get-vw(-130px, 1920px), -130px);
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include devices(tablet) {
    max-width: 644px;
    padding: 20px 40px;
    margin: 0;
  }

  @include devices(mobile) {
    max-width: 100%;
    padding: 20px 12px 30px;
    gap: 10px;
    text-align: center;
  }
}

.title {
  color: var(--dark-gold);

  @include devices(mobile) {
    margin: 0 auto;
    max-width: 246px;
    text-align: center;
  }

  h1 {
    font-weight: 500;
  }
}

.description {
  padding: 30px 0 0 min(150px, get-vw(150px, 1920px));

  @include devices(tablet) {
    padding: 0 40px;
  }

  @include devices(mobile) {
    padding: 0 12px;
    text-align: center;
  }
}