@import "@/shared/scss/mixins/devices";

.container {
  margin-bottom: 150px;

  @include devices(tablet) {
    margin-bottom: 106px;
  }

  @include devices(mobile) {
    margin-bottom: 30px;
  }
}

.header {
  display: flex;
  position: relative;
  justify-content: center;

  @media (width <= 1420px) {
    flex-flow: column;
    align-items: center;
    gap: 30px
  }
}

.button {
  position: absolute;
  top: 0;
  right: 0;

  @media (width <= 1420px) {
    position: relative;
    max-width: 205px;
  }
}

.title {
  max-width: 616px;
  text-align: center;
}

.top {
  margin-bottom: 77px;

  @include devices(mobile) {
    margin-bottom: 30px;
  }
}