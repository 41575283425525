@import "@/shared/scss/mixins/devices";

.line {
  width: 7.9167vw;
  height: 2px;
  background-color: #C59669;
  min-width: 120px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  text-align: center;

  @include devices(mobile) {
    flex-flow: column;
    gap: 36px;
  }
}