@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 150px;

  @include devices(mobile) {
    margin-bottom: 100px;
  }
}

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 100px;

  @media (width <= 1420px) {
    flex-flow: column;
    align-items: center;
    gap: 30px
  }
}

.title {
  max-width: 616px;
  text-align: center;
}

.top {
  margin-bottom: 77px;

  @include devices(mobile) {
    margin-bottom: 30px;
  }
}

