@import "@/shared/scss/mixins/get-vw";
@import "@/shared/scss/mixins/devices";

.section {

  @include devices(mobile) {
    padding: 0;
  }
}

.container {
  display: flex;

  @include devices(tablet) {
    gap: 21px;
    margin-bottom: 164px;
  }

  @include devices(mobile) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 80px;
  }
}

.image {
  width: get-vw(1617px, 1920px);
  position: relative;
  margin-right: 36px;

  img {
    object-fit: cover;
    height: 100%;
  }

  @include devices(tablet) {
    margin-right: 0;
    justify-content: space-between;
  }

  @include devices(mobile) {
    width: 100%;
    height: 200px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}