@import "@/shared/scss/mixins/devices";

.gradient {
  width: 400%;
  height: 200%;
  top: -5px;
  left: 0;
  position: absolute;
  background: linear-gradient(to right, rgb(248 179 112 / 0%), #F8B370 40%, #F8B370 50%, #F8B370 70%, rgb(248 179 112 / 0%));
  transform: translate(-100%, -5px);
  z-index: -1;
}

.container {
  position: relative;

  a,
  button {
    color: var(--white);
    background-color: var(--dark-gold);
    padding: 6px;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 60px;

    &:hover {
      text-decoration: none;
    }

    &:not(:disabled, .secondary):hover {
      .gradient {
        animation: animated-blink-skewed 4s infinite;
      }
    }

    @include devices(mobile) {
      gap: 10px;
      min-height: 50px;
    }

  }

  &.thin {
    color: black;

    a,
    button {
      border: 1px solid var(--dark-gold);
      color: black;
      background-color: white;
      transition: background-color .3s ease-in-out;

      &:hover {
        background-color: var(--light);
        text-decoration: none;
      }
    }

    &::after,
    &::before,
    .gradient {
      display: none;
    }
  }
}

.secondary {
  background-color: white;
  min-width: 205px;
  min-height: 60px;

  a,
  button {
    position: relative;
    background-clip: text;
    background-image: linear-gradient(red, red);
    color: var(--dark-gold);
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1px;
      background: linear-gradient(to right, var(--dark-gold), rgb(248 179 112 / 100%) 50%, var(--dark-gold) 60%, var(--dark-gold));
      background-size: 250% 200%;
      background-position: 100% 50%;
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: xor;
      mask-composite: exclude;
      z-index: -1;
    }

    &:not(:disabled):hover {
      text-decoration: none;

      &::after {
        animation: animate-border 3s infinite;
      }
    }
  }

  @include devices(mobile) {
    min-height: 40px;
  }
}

.disabled {

  a:not(.secondary),
  button:not(.secondary) {
    cursor: not-allowed;
    background-color: #E1CAB4;
  }

  &a.secondary,
  button.secondary {
    cursor: not-allowed;
    border-color: #E1CAB4;
    color: #E1CAB4;

    &::after {
      background: #E1CAB4;
    }
  }
}

@keyframes animated-blink {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes animated-blink-skewed {
  0% {
    transform: translate(-100%, -5px) skew(-45deg);
  }
  100% {
    transform: translate(100%, -5px) skew(-45deg);
  }
}

@keyframes animate-border {
  0% {
    background-position: 100% 50%
  }
  100% {
    background-position: -50% 50%
  }
}

.icon {
  display: flex;
  align-items: center;
}