@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 49px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 973px;
  margin: 0 auto;

  @include devices(mobile) {
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
}

.column {
  grid-column: span 2;
}

.center {
  grid-column: 4 / 6;

  @include devices(mobile) {
    grid-column: span 2;
  }
}

.mb {

  @include devices(mobile) {
    margin-bottom: 30px;
  }
}

.title {
  font-size: min(19px, get-vw(19px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(35px, get-vw(35px, 1920px)); /* 184.211% */
  letter-spacing: 0.19px;
  text-transform: uppercase;

  @include devices(tablet) {
    font-size: min(19px, get-vw(19px, 1200px));
    line-height: min(35px, get-vw(35px, 1200px)); /* 184.211% */
  }

  @include devices(mobile) {
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.15px;
  }
}

.text {
  color: black;
  font-size: min(19px, get-vw(19px, 1920px));
  font-style: normal;
  font-weight: 600;
  line-height: min(35px, get-vw(35px, 1920px)); /* 184.211% */
  letter-spacing: 0.19px;

  @include devices(tablet) {
    font-size: min(19px, get-vw(19px, 1200px));
    line-height: min(35px, get-vw(35px, 1200px)); /* 184.211% */
  }

  @include devices(mobile) {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 173.333% */
    letter-spacing: 0.15px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 6px;

  @include devices(mobile) {
    flex-direction: row;
  }
}

.row {
  display: flex;
  gap: 6px
}

.grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  gap: 6px
}

.gray {
  color: var(--gray3);
}

.gold {
  color: var(--dark-gold);
}