@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  color: black;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 min(150px, get-vw(150px, 1920px));
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);

  @include devices(laptop) {
    padding: 0 get-vw(73px, 1440px);
  }

  @include devices(tablet) {
    padding: 0 42px;
  }

  @include devices(mobile) {
    display: none;
  }
}

.container-absolute {
  background-color: transparent;
  color: white;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.overlay {
  background-image: linear-gradient(to bottom, rgb(0 0 0 / 70%), rgb(0 0 0 / 0%));
  position: absolute;
  left: 0;
  top: 0;
  min-height: 20vh;
  width: 100%;
  pointer-events: none;
  z-index: -1;
}

.logo {
  padding-top: 15px;
  color: currentColor;
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 51.2963%;
}

.cta {
  margin-top: 18px;
  width: 13.3951%;

  @include devices(tablet) {
    width: auto;
  }
}

.button {

  button {
    min-height: 56px!important;
  }

  @include devices(tablet) {
    min-height: auto;
  }
}

.brand {
  display: flex;
  align-items: center;
  margin-top: 18px;
}

.phone {
  color: currentColor;
  margin-top: 18px;
  display: flex;

  a {
    color: currentColor;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  @media (width <= 1600px) {
    width: 33px;
    height: 33px;
    border: 1px solid currentColor;
    border-radius: 100%;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.number {
  
  @media (width <= 1600px) {
    display: none;
  }
}