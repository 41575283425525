@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  display: flex;
  flex-direction: column;
  gap: 65px;

  @include devices(mobile) {
    gap: 40px
  }
}

.tabs {
  display: flex;
  gap: 60px;
  flex: 1;
  width: 100%;

  @include devices(mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    justify-content: space-between;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  @include devices(mobile) {
    flex-direction: column;
    flex: 1;
  }
}

.paddings {
  padding: 0 min(150px, get-vw(150px, 1920px));

  @include devices(tablet) {
    padding: 0 min(42px, get-vw(42px, 1200px));
  }

  @include devices(mobile) {
    padding: 0 12px;
  }
}

.desktop {
  display: block;

  @include devices(mobile) {
    display: none;
  }
}

.mobile {
  display: none;
  max-width: 225px;
  margin: 0 auto;

  @include devices(mobile) {
    display: block;
  }
}

.tab {
  color: var(--gray4);
  font-size: min(38px, get-vw(38px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(48px, get-vw(48px, 1920px)); /* 126.316% */
  text-decoration: underline;

  @include devices(mobile) {
    font-size: 22px;
    line-height: 24px; /* 109.091% */
  }
}

.active {
  color: black;

  @include devices(mobile) {
    font-size: 22px;
    font-weight: 400;
    line-height: 24px; /* 109.091% */
  }
}

.content {

  @include devices(mobile) {
    margin-bottom: 50px;
  }
}