@import "@/shared/scss/mixins/devices";
@import "@/shared/scss/mixins/get-vw";

.container {
  color: var(--dark-gold);
  padding: 51px 0;
  gap: 24px;
  display: flex;
  justify-content: space-between;

  @include devices(tablet) {
    gap: 27px;
  }


  @include devices(mobile) {
    flex-direction: column;
    gap: 20px;
    padding: 0 0 63px;
  }
}

.border {
  border-top: 1px solid var(--dark-gold);

  @include devices(mobile) {
    padding: 0;
  }
}

.golden {
  color: var(--dark-gold);
  line-height: min(35px, get-vw(35px, 1920px));

  @include devices(mobile) {
    line-height: 35px;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  max-width: min(636px, get-vw(636px, 1920px));
  width: 100%;

  @include devices(tablet) {
    max-width: min(636px, get-vw(636px, 1200px));
  }

  @include devices(mobile) {
    display: none;
  }
}

.column,
.links {
  display: flex;
  flex-direction: column;
  gap: min(16px, get-vw(16px, 1920px));
}

.link {
  color: var(--dark-gold);
  font-size: min(16px, get-vw(16px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(24px, get-vw(24px, 1920px)); /* 150% */
  letter-spacing: 0.16px;

  &:hover {
    text-decoration: underline;
  }

  @include devices(tablet) {
    font-size: min(16px, get-vw(16px, 1200px));
    line-height: min(24px, get-vw(24px, 1200px)); /* 150% */
  }

  @include devices(mobile) {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
}

.bold {
  color: var(--dark-gold);
  font-size: min(15px,  get-vw(15px, 1920px));
  font-style: normal;
  font-weight: 500;
  line-height: min(35px, get-vw(35px, 1920px)); /* 233.333% */
  letter-spacing: 0.15px;
  text-transform: uppercase;

  @include devices(tablet) {
    font-size: min(15px,  get-vw(15px, 1200px));
    line-height: min(35px, get-vw(35px, 1200px));
  }
}

.about {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: min(494px, get-vw(494px, 1920px));

  @include devices(tablet) {
    max-width: 282px;
  }

  @include devices(mobile) {
    max-width: 100%;
  }
}

.small {
  font-size: min(16px, get-vw(16px, 1920px));
  font-style: normal;
  font-weight: 300;
  line-height: min(24px, get-vw(24px, 1920px)); /* 150% */
  letter-spacing: 0.16px;

  @include devices(tablet) {
    font-size: min(16px, get-vw(16px, 1200px));
    line-height: min(24px, get-vw(24px, 1200px));
  }

  @include devices(mobile) {
    text-align: center;
    order: 1;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
  }
}

.socials {
  display: flex;
  gap: 40px;
  align-items: center;
  width: 100%;

  svg {
    transition: all .3s ease-in-out;
  }

  li {
    display: flex;
  }

  a {
    display: flex;
    color: var(--light-gold);

   &:hover {
     &.youtube {
       color: #b00
     }

     &.dzen {
       color: black
     }

     &.telegram {
       color: #2AABEE
     }

     &.vk {
       color: #4C75A3;
     }
   }
  }

  @include devices(mobile) {
    justify-content: center;
    gap: 40px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @include devices(tablet) {
    min-width: 161px;
  }

  @include devices(mobile) {
    text-align: center;
    align-items: center;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: min(75px, get-vw(75px, 1920px));

  @include devices(mobile) {
    gap: 18px;
    margin-bottom: 18px;
  }
}

.mobile {
  display: none;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  @include devices(mobile) {
    display: flex;
  }
}

.pinkpixel {
  display: flex;
  flex-direction: column;
  color: var(--dark-gold);

  &:hover {
    color: #F06;
    text-decoration: none;
  }

  svg {
    transition: all .3s ease-in-out;
  }

  span {
    color: #AA845E;
    font-size: min(13px, get-vw(13px, 1920px));
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.13px;
    margin-top: 9px;

    @include devices(mobile) {
      font-size: 13px;
    }
  }

  @include devices(mobile) {
    align-items: center;
  }
}