@import "@/shared/scss/mixins/devices";

.container {

  @include devices(tablet) {
    margin-bottom: 166px;
  }

  @include devices(mobile) {
    margin-bottom: 89px;
  }
}