@import "@/shared/scss/mixins/get-vw";

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  max-width: min(536px, get-vw(536px, 1920px));
  padding: 20px;
  background-color: rgb(0 0 0 / 50%);
  opacity: 0;
  visibility: hidden;
  display: grid;
  justify-content: space-between;
  gap: 24px 31px;
  transition: all .2s ease-in-out;
  width: min(536px, get-vw(536px, 1920px));

  &.three {
    grid-template-columns: repeat(3, 1fr);
  }

  &.two {
    grid-template-columns: repeat(2, 1fr);
  }

  span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px
  }

  a {
    color: white;
    font-size: min(15px, get-vw(15px, 1920px));
    font-weight: 400;
    line-height: min(24px, get-vw(24px, 1920px));
    letter-spacing: 0.01em;
    text-align: left;
  }
}

.chevron {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% + 9px);
  right: 13px;
  transform: translateY(-50%);
  transition: transform .2s ease-in-out;
}

.button {
  display: inline-block;
  height: 100%;
  position: relative;
  color: currentColor;
  cursor: pointer;
  padding: 44px 23px 23px 20px;
  transition: background-color .2s ease-in-out;

  &:hover {
    text-decoration: none;
  }
}

.children {
  white-space: nowrap;
  padding: 44px 41px 23px 20px;
}

.container {
  position: relative;
  display: flex;
  align-items: flex-end;

  &:hover {
    .dropdown {
      opacity: 1;
      visibility: visible;
    }
    .chevron {
      transform: translateY(-50%) rotate(180deg);
    }
    .button {
      background-color: rgb(0 0 0 / 50%);
      color: white;
    }
  }
}

.button-container {
  position: relative;
}