@mixin devices ($breakpoint) {

  @if $breakpoint == laptop {
    @media only screen and (width <= 1440px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (width <= 1200px) {
      @content;
    }
  }

  @if $breakpoint == tablet-portrait {
    @media only screen and (width <= 768px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (width <= 900px) {
      @content;
    }
  }
}