@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: black;

  &:hover {
    text-decoration-line: underline;
	text-decoration-thickness: 1.5px;
  }
}

.poster {
  flex: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.label {
  position: absolute;
  bottom: 39px;
  left: 39px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px; /* 250% */
  letter-spacing: 0.42px;
  padding: 0 14px;
  z-index: 2;
  color: white;
  text-transform: uppercase;

  @include devices(tablet) {
    left: 34px;
    bottom: 38px;
  }

  @include devices(mobile) {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: 0.13px;
    left: 19px;
    bottom: 19px;
  }
}

.label--golden {
  background: var(--dark-gold);
}

.label--green {
  background: linear-gradient(90deg, #007762 0%, #00CBD8 100%);
}