@import "@/shared/scss/mixins/devices";

.section {
  margin-bottom: 100px;

  @include devices(tablet) {
    margin-bottom: 60px;
  }

  @include devices(mobile) {
    margin-bottom: 30px;
    padding: 0!important;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: space-between;

  @include devices(mobile) {
    padding: 0 12px;
    gap: 30px
  }
}

.header {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 67px;
}
.button {
  position: absolute;
  top: 0;
  right: 0;

  @media (width <= 1420px) {
    position: relative;
    max-width: 205px;
  }
}
.bottom {
  display: flex;
  flex-flow: column;
  gap: 10px;

  @include devices(mobile) {
    gap: 30px;
    align-items: center;
    text-align: center;
  }
}

.text {
  max-width: 375px;
  margin-bottom: 34px;
}

.image {
  width: auto!important;
  height: auto!important;
}