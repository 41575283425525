.container {
  position: relative;
  width: 100%;
}

.dropdown {
  overflow: hidden;
}

.items {
  display: flex;
  flex-flow: column;
  gap: 14px;
  padding: 14px 0 22px;
}

.gold {
  background-color: var(--light);
}

.header {
  display: inline;
  position: relative;
}

.icon {
  position: absolute;
  display: flex;
  top: 50%;
  right: -20px;
}

.link {
  color: black;
}