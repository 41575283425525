.container {
  display: flex;
  position: relative;
  justify-content: center;

  @media (width <= 1420px) {
    flex-flow: column;
    align-items: center;
    gap: 30px
  }
}

.title {
  flex: 1;
  text-align: center;
}

.button {
  position: absolute;
  top: 0;
  right: 0;

  @media (width <= 1420px) {
    position: relative;
    max-width: 205px;
  }
}