@import "@/shared/scss/mixins/devices";

.container {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
  text-align: center;
  min-height: 115px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include devices(mobile) {
    > div {

      &:first-of-type {
        padding-left: 13px;
      }
    }
  }
}

.separator {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  height: 115px;
  width: 2px;
  background-color: var(--dark-gold);
  transition: all .8s ease-out;
  transform: scaleY(0);
  transform-origin: 100% 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, rgb(248 179 112 / 0%), #fcc0b0 40%, #ffa258 50%, #ffe9c8 70%, rgb(248 179 112 / 0%));
    transform: translate(0, -100%);
    z-index: 1;
  }
}

.container-in-view {

  .separator {
    transform: scaleY(1);

    &::before {
      animation: animated-blink 3s infinite;
    }
  }
}

@keyframes animated-blink {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}