@import "@/shared/scss/mixins/devices";

.complexes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 150px;

  @include devices(tablet) {
    gap: 24px;
    margin-bottom: 100px;
  }

  @include devices(mobile) {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    padding: 0!important;
  }
}

.big {

  @include devices(mobile) {
    display: none!important;
  }
}

.right {
  display: flex;
  flex-direction: column;
  gap: 104px;
  justify-content: space-between;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  text-align: center;
  padding-top: 10px;
}

.articles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;

  @include devices(tablet) {
    gap: 24px;
  }

  @include devices(mobile) {
    display: flex;
    max-width: 100%;
    overflow: auto;
    gap: 19px;
    padding: 0 12px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.mobile {
  display: none;

  @include devices(mobile) {
    display: flex;
  }
}

.article {

  @include devices(mobile) {
    flex-shrink: 0;
    width: 200px;
  }
}

.section {

  @include devices(mobile) {
    padding: 0!important;
  }
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;

  @include devices(mobile) {
    margin: 0;
    flex-direction: column;
  }
}

.buttons_wrapper > div {
  width: calc(50% - 15px);
  margin: 0 15px;
  @include devices(mobile) {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
  }
}

.video_button {
  button {
    background: var(--dark-gold)!important;
    transition: all .5s;
  }
  button:hover {
    opacity: .9;
    transition: all .5s;
  }
  span {
    color: #fff;
  }
  svg  {
    path {
      fill: #fff;
    }
  }
  
}

.button {
  span {
    text-align: left;
  }
}

.padding {

  @include devices(mobile) {
    padding: 0 12px;
  }
}